import React, { Component } from "react";
import { Link } from "react-router-dom";
import AuthenticationService from "./AuthenticationService.js";

class HeaderComponent extends Component {
  render() {
    const isUserLoggedIn = AuthenticationService.isUserLoggedIn();
    //console.log(isUserLoggedIn);

    return (
      <header className="header">
        <nav className="navbar navbar-expand-md navbar-dark bg-dark">
          <div style={{ marginLeft: "50px" }}>
            <a href="aboutUs" className="navbar-brand">
              TECH VENTURE
            </a>
          </div>
          <ul className="navbar-nav" style={{ marginLeft: "30px" }}>
            {isUserLoggedIn && (
              <li>
                <Link className="nav-link" to="/welcome/in28minutes">
                  Home
                </Link>
              </li>
            )}

            <li>
              <Link className="nav-link" to="/home">
                Interview Guide
              </Link>
            </li>
            <li>
              <Link className="nav-link" to="/questions">
                Java
              </Link>
            </li>
            <li>
              <Link className="nav-link" to="/spring">
                Spring
              </Link>
            </li>
            <li>
              <Link className="nav-link" to="/hibernate">
                Hibernate
              </Link>
            </li>
            <li>
              <Link className="nav-link" to="/react">
                React
              </Link>
            </li>
            <li>
              <Link className="nav-link" to="/sql">
                sql
              </Link>
            </li>
            <li>
              <Link className="nav-link" to="/javascript">
                JavaScript
              </Link>
            </li>
            <li>
              <Link className="nav-link" to="/search">
                Quick Search
              </Link>
            </li>
            <li>
              <Link className="nav-link" to="/codingRound">
                Coding Interviews
              </Link>
            </li>
            {/*<li>
              <Link className="nav-link" to="/blind75">
                Blind 75
              </Link>
            </li>
            <li>
              <Link className="nav-link" to="/git">
                Git
              </Link>
            </li>
            <li>
              <Link className="nav-link" to="/consul">
                Consul
              </Link>
            </li>
            <li>
              <Link className="nav-link" to="/cdn">
                CDN
              </Link>
             </li> */}
            <li>
              <Link className="nav-link" to="/blogs">
                Blogs
              </Link>
            </li>

            {isUserLoggedIn && (
              <li>
                <Link className="nav-link" to="/todos">
                  Todos
                </Link>
              </li>
            )}
            {isUserLoggedIn && (
              <li>
                <Link className="nav-link" to="/addQuestion/-1">
                  Add Questions
                </Link>
              </li>
            )}
            <div>
              <Link className="nav-link" to="/projects">
                Projects
              </Link>
            </div>
            {/*<div>
              <Link className="nav-link" to="/blog/java/streamAPI">
                Stream API
              </Link>
            </div>
            <div>
              <Link className="nav-link" to="/interviewProcess">
                Interview Process
              </Link>
            </div>
            <div>
              <Link className="nav-link" to="/prepareForInterview">
                Prepare For Interview
            </Link> 
            </div>*/}
            <div>
              <Link className="nav-link" to="/abilityTest">
                Ability Test
              </Link>
            </div>
            <div>
              <Link className="nav-link" to="/androidApps">
                Android Apps
              </Link>
            </div>
            <div>
              <Link className="nav-link" to="/privacyPolicyMain">
                Privacy Policy
              </Link>
            </div>

            {/*  <div>
              <Link className="nav-link" to="/AmazonProducts">
                Amazon Products Review
              </Link>
            </div>
            <div>
              <Link className="nav-link" to="/WalmartProducts">
                Walmart Products Review
              </Link>
            </div>
          */}
          </ul>
          {/*  <ul className="navbar-nav navbar-collapse justify-content-end">
            {!isUserLoggedIn && (
              <li>
                <Link className="nav-link" to="/login">
                  Login
                </Link>
              </li>
            )}
            {isUserLoggedIn && (
              <li>
                <Link
                  className="nav-link"
                  to="/logout"
                  onClick={AuthenticationService.logout}
                >
                  Logout
                </Link>
              </li>
            )}
          </ul>*/}
        </nav>
      </header>
    );
  }
}

export default HeaderComponent;
