import React from "react";

const BlogsContent = () => {
  return (
    <div>
      <h1 className="text-primary mb-3">
        Top Technology Blogs to Stay Updated
      </h1>
      <p className="text-left lead text-justify text-break">
        Stay on top of the latest advancements and trends in various industries
        by reading technology blogs. These blogs provide valuable information on
        cutting-edge technologies, best practices, and expert insights that can
        help professionals expand their knowledge base and improve their skills.
        Keep yourself informed about the latest industry news and developments,
        learn about new tools and techniques, and gain valuable tips and tricks
        from experienced practitioners. Engage in meaningful discussions about
        your field and build a network of like-minded professionals. Reading
        technical blogs can be an effective way to stay competitive and advance
        in your career. Discover the top technology blogs to stay updated and
        enhance your expertise today.
      </p>
      <br />
      <div className="container">
        <div class="row">
          <div class="card-deck mb-4">
            <div class="card">
              <a href="/git">
                <div class="card-body">
                  <h5 class="card-title">Lets Understand Git</h5>
                  <p class="card-text">
                    Git is a distributed version control system designed to help
                    developers track changes to their codebase, collaborate with
                    other developers, and easily revert to previous versions of
                    their code.
                  </p>
                </div>
              </a>
            </div>

            <div class="card">
              <a href="/consul">
                <div class="card-body">
                  <h5 class="card-title">What is Consul ?</h5>
                  <p class="card-text">
                    Consul is a popular open source tool developed by HashiCorp
                    for service discovery, service mesh, and configuration
                    management. It provides a centralized platform for
                    service-oriented architectures, enabling services to
                    register and discover each other using DNS or HTTP.
                  </p>
                </div>
              </a>
            </div>
            <div class="container card">
              <a href="/cdn">
                <div class="card-body">
                  <h5 class="card-title">Why is CDN Popular ?</h5>
                  <p class="card-text">
                    A Content Delivery Network (CDN) is a network of
                    geographically distributed servers that store website
                    content and deliver it to users from the closest server,
                    improving website performance and reliability.
                  </p>
                </div>
              </a>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="card-deck mb-4">
            <div class="card">
              <a href="/blind75">
                <div class="card-body">
                  <h5 class="card-title">
                    75 Most Asked Coding Interview Questions
                  </h5>
                  <p class="card-text">
                    There are some most popular Coding Round interview questions
                    frequently asked mainly because these questions actually
                    helps to understand the depth of you understandin the bacis
                    and the problem solving techniques.
                  </p>
                </div>
              </a>
            </div>
            <div class="card">
              <a href="/prepareForInterview">
                <div class="card-body">
                  <h5 class="card-title">Prepare For Programming Interview</h5>
                  <p class="card-text">
                    Review the basics: Brush up on your algorithms and data
                    structures, as these form the foundation of most programming
                    interviews.
                  </p>
                </div>
              </a>
            </div>
            <div class="container card">
              <a href="/interviewProcess">
                <div class="card-body">
                  <h5 class="card-title">Common Interview Process</h5>
                  <p class="card-text">
                    It's important to prepare well for each stage, be confident
                    and professional, and clearly articulate your skills and
                    experience to increase your chances of success.
                  </p>
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlogsContent;
