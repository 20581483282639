import React, { Component } from "react";
import DisplayContent from "./DisplayContent";
import { Form, FormControl } from "react-bootstrap";
import QnASideBarRight from "../questionanswer/QnASideBarRight";
import BlogSideBarRight from "../blogs/BlogSideBarRight";

class PrivacyPolicyMain extends Component {
  render() {
    return (
      <div className="container-fluid">
        <div className="row flex-xl-nowrap">
          <div className="col-6 col-md-2 clo-xl-2 bd-sidebar mb-2 border-right border-bottom">
            <Form className="form-center p-2">
              <FormControl type="text" placeholder="Search" className="" />
            </Form>
          </div>
          <main
            className="col-12 col-md-9 col-xl-8 py-md-3 pl-md-5 bd-content"
            role="main"
          >
            <h1 className="m-5">Privacy Policy</h1>

            <div className="text-left lead text-justify text-break">
              <div>
                Our website is dedicated to providing information about various
                technologies that are widely used across software companies and
                helping users prepare for tech interviews. We do not collect any
                personal information from our users, nor do we use cookies or
                any other tracking technologies. We respect the privacy of our
                users and are committed to maintaining the confidentiality of
                any information that is provided to us.
              </div>
              <br />
              <div>
                Please note that while we do our best to provide accurate and
                up-to-date information, we cannot guarantee the accuracy or
                completeness of the information on our website. Users should
                always verify any information before relying on it, and we
                accept no liability for any errors or omissions.
              </div>
              <br />
              <div>
                If you have any questions about our privacy policy, please
                contact us at techventure2022@gmail.com .
              </div>
            </div>
            <br />
          </main>
          <div className="col-6 col-md-2 clo-xl-2 mb-2 border-left"></div>
        </div>
      </div>
    );
  }
}

export default PrivacyPolicyMain;
