import React, { useState, useEffect } from "react";
import axios from "axios";
import Posts from "./Posts.js";
import Pagination from "./Pagination.js";
import { QA_API_URL } from "../../Constants";

const MainPageContent = () => {
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState(10);

  useEffect(() => {
    const fetchPosts = async () => {
      setLoading(true);
      const res = await axios.get(`${QA_API_URL}/qa/all`);
      setPosts(res.data);
      setLoading(false);
    };
    fetchPosts();
  }, []);

  //get current posts
  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = posts.slice(indexOfFirstPost, indexOfLastPost);

  //change page
  const paginate = pageNumber => setCurrentPage(pageNumber);

  return (
    <div>
      <h1 className="text-primary mb-3">
        Welcome to the official Page of Tech Venture LLC
      </h1>
      <div className="container">
        <div className="text">
          Since 2022, Tech Venture LLC has been a website hosting provider that
          delivers full featured, high quality hosting services at low prices to
          small and medium size firms in the Washington metro area . You can
          find everything you need to get your web site up and running with us!
          Tech Venture LLC is an IT consulting firm that provides innovative
          solutions to serve small and medium size organizations technical
          needs. We are committed to expedite tangible business results by
          offering a comprehensive in-premise or hosted solution and an
          unmatched experience for a successful web implementation.
        </div>

        <div className="text">
          Our team is committed to help you throughout all the stages of the
          software development life cycle. Thank you for believing us.
        </div>
      </div>

      <Pagination
        postsPerPage={postsPerPage}
        totalPosts={posts.length}
        paginate={paginate}
      />
      <div className="container">
        <div class="row">
          <div class="card-deck mb-4">
            <div class="card">
              <img
                class="card-img-top"
                src="https://rszero3runit.files.wordpress.com/2016/03/software-2.jpg"
                alt="Card image cap"
              />
              <div class="card-body">
                <h5 class="card-title">Who we are</h5>
                <p class="card-text">
                  We are committed to develop secure, user friendly and right
                  systems and softwares.
                </p>
                <p class="card-text">
                  <small class="text-muted">Last updated 3 days ago</small>
                </p>
              </div>
            </div>
            <div class="card">
              <img
                class="card-img-top"
                src="https://s3-us-east-2.amazonaws.com/maryville/wp-content/uploads/2020/01/22111038/software-developer-4.jpg"
                alt="Card image cap"
              />
              <div class="card-body">
                <h5 class="card-title">What we live by</h5>
                <p class="card-text">
                  We support our client whenever they need, we are always
                  standby 24/7.
                </p>
                <p class="card-text">
                  <small class="text-muted">Last updated 3 days ago</small>
                </p>
              </div>
            </div>
            <div class="container card">
              <img
                class="card-img-top"
                src="https://www.sas.com/en_us/home/_jcr_content/par/styledcontainer_7329/par/image_dc14.img.png/1653512134851.png"
                alt="Card image cap"
              />
              <div class="card-body">
                <h5 class="card-title">Where we're located</h5>
                <p class="card-text">
                  We are located in heart of Sterling, close to the capital but
                  we are one text/call away from you.
                </p>
                <p class="card-text">
                  <small class="text-muted">Last updated 3 days ago</small>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MainPageContent;
