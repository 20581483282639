import React, { useState, useEffect } from "react";
import { Button, Card, Form } from "react-bootstrap";
import { ReactQuestionData } from "./ReactQuestionData";
import { JavaQuestionData } from "./JavaQuestionData";
import Review from "./Review";

const Quiz = ({ quizType }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [showResult, setShowResult] = useState(false);
  const [score, setScore] = useState(0);
  const [selectedAnswer, setSelectedAnswer] = useState(null);
  const [timeLeft, setTimeLeft] = useState(60);
  const [reviewAnswers, setReviewAnswers] = useState([]);

  const questions = quizType === "java" ? JavaQuestionData : ReactQuestionData;

  useEffect(() => {
    if (timeLeft === 0) {
      setShowResult(true);
    }

    const timer = setTimeout(() => {
      setTimeLeft(timeLeft - 1);
    }, 1000);

    return () => clearTimeout(timer);
  }, [timeLeft]);

  /*

  const handleNextClick = () => {
    console.log("Score is : " + score);
    console.log("selectedAnswer" + selectedAnswer);
    if (selectedAnswer === null) {
      alert("Please select an answer");
      return;
    }

    if (selectedAnswer) {
      setScore(score + 1);
    }

    if (currentIndex < questions.length - 1) {
      setCurrentIndex(prevIndex => prevIndex + 1);
      setSelectedAnswer(null);
    } else {
      setShowResult(true);
    }
  };

  */

  const handleNextClick = () => {
    if (selectedAnswer === null) {
      alert("Please select an answer");
      return;
    }

    const isCorrect = questions[currentIndex].correctAnswer === selectedAnswer;
    setReviewAnswers(prevReviewAnswers => [
      ...prevReviewAnswers,
      {
        question: questions[currentIndex].question,
        selectedAnswer,
        isCorrect,
        correctAnswer:
          questions[currentIndex].answers[questions[currentIndex].correctAnswer]
      }
    ]);

    console.log("selectedAnswer" + selectedAnswer);
    console.log("correctAnswer" + questions[currentIndex].correctAnswer);

    if (isCorrect) {
      setScore(prevScore => prevScore + 1);
    }

    console.log("Score is : " + score);

    if (currentIndex < questions.length - 1) {
      setCurrentIndex(prevIndex => prevIndex + 1);
      setSelectedAnswer(null);
    } else {
      setShowResult(true);
    }
  };

  const handleAnswerChange = event => {
    setSelectedAnswer(parseInt(event.target.value));
  };

  const handleRestartClick = () => {
    setShowResult(false);
    setCurrentIndex(0);
    setScore(0);
    setSelectedAnswer(null);
    setTimeLeft(60);
  };

  const handleAnswerOptionClick = isCorrect => {
    if (isCorrect) {
      setScore(score + 1);
    }
  };

  const reviewMyAnswers = () => {
    setShowResult(false);
  };

  const renderResult = () => {
    return (
      <Card>
        <Card.Body>
          <Card.Title>Quiz Result</Card.Title>
          <Card.Text>
            You got {score} out of {questions.length} correct!
          </Card.Text>
          <Button variant="primary" onClick={handleRestartClick}>
            Restart Quiz
          </Button>
          <Button variant="secondary" onClick={reviewMyAnswers}>
            Review My Quiz
          </Button>
        </Card.Body>
      </Card>
    );
  };

  const renderQuiz = () => {
    const question = questions[currentIndex];

    return (
      <Card>
        <Card.Body>
          <Card.Title>Question {currentIndex + 1}</Card.Title>
          <Card.Text>{question.question}</Card.Text>
          <Form.Group>
            {question.answers.map((answer, index) => (
              <Form.Check
                type="radio"
                label={answer}
                name="answer"
                key={index}
                value={index}
                onChange={handleAnswerChange}
                checked={selectedAnswer === index}
              />
            ))}
          </Form.Group>
          <Button variant="primary" onClick={handleNextClick}>
            {currentIndex < questions.length - 1 ? "Next" : "Finish"}
          </Button>
          <div className="float-right">{timeLeft} seconds left</div>
        </Card.Body>
      </Card>
    );
  };

  return <>{showResult ? renderResult() : renderQuiz()}</>;
};
/*return (
    <>
      {showResult ? (
        <Review reviewAnswers={reviewAnswers} />
      ) : showResult === false && reviewAnswers.length > 0 ? (
        <Button variant="primary" onClick={() => setShowResult(true)}>
          Review My Quiz
        </Button>
      ) : (
        renderQuiz()
      )}
      {showResult && renderResult()}
    </>
  );
};*/

export default Quiz;

/*import React, { useState } from "react";
import { Button, Card, Form } from "react-bootstrap";
import { ReactQuestionData } from "./ReactQuestionData";
import { JavaQuestionData } from "./JavaQuestionData";
import Result from "./Result";
import Review from "./Review";

const Quiz = ({ quizType }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [showResult, setShowResult] = useState(false);
  const [score, setScore] = useState(0);
  const [selectedAnswer, setSelectedAnswer] = useState(null);
  const [reviewAnswers, setReviewAnswers] = useState([]);

  const questions = quizType === "java" ? JavaQuestionData : ReactQuestionData;

  const handleNextClick = () => {
    if (selectedAnswer === null) {
      alert("Please select an answer");
      return;
    }

    const isCorrect =
      questions[currentIndex].correctAnswerIndex === selectedAnswer;
    setReviewAnswers(prevReviewAnswers => [
      ...prevReviewAnswers,
      {
        question: questions[currentIndex].question,
        selectedAnswer,
        isCorrect,
        correctAnswer:
          questions[currentIndex].answers[
            questions[currentIndex].correctAnswerIndex
          ]
      }
    ]);

    if (isCorrect) {
      setScore(prevScore => prevScore + 1);
    }

    if (currentIndex < questions.length - 1) {
      setCurrentIndex(prevIndex => prevIndex + 1);
      setSelectedAnswer(null);
    } else {
      setShowResult(true);
    }
  };

  const handleAnswerChange = event => {
    setSelectedAnswer(parseInt(event.target.value));
  };

  const handleRestartClick = () => {
    setShowResult(false);
    setCurrentIndex(0);
    setScore(0);
    setSelectedAnswer(null);
    setReviewAnswers([]);
  };

  const renderResult = () => {
    return (
      <Result
        score={score}
        totalQuestions={questions.length}
        handleRestartClick={handleRestartClick}
        handleReviewClick={() => setShowResult(false)}
      />
    );
  };

  const renderQuiz = () => {
    const question = questions[currentIndex];

    return (
      <Card>
        <Card.Body>
          <Card.Title>Question {currentIndex + 1}</Card.Title>
          <Card.Text>{question.question}</Card.Text>
          <Form.Group>
            {question.answers.map((answer, index) => (
              <Form.Check
                type="radio"
                label={answer}
                name="answer"
                key={index}
                value={index}
                onChange={handleAnswerChange}
                checked={selectedAnswer === index}
              />
            ))}
          </Form.Group>
          <Button variant="primary" onClick={handleNextClick}>
            {currentIndex < questions.length - 1 ? "Next" : "Finish"}
          </Button>
        </Card.Body>
      </Card>
    );
  };

  return (
    <>
      {showResult ? (
        <Review reviewAnswers={reviewAnswers} />
      ) : showResult === false && reviewAnswers.length > 0 ? (
        <Button variant="primary" onClick={() => setShowResult(true)}>
          Review My Quiz
        </Button>
      ) : (
        renderQuiz()
      )}
      {showResult && renderResult()}
    </>
  );
};

export default Quiz; */

/*import React, { useState, useEffect } from "react";
import { Form, Button, Card } from "react-bootstrap";
import { ReactQuestionData } from "./ReactQuestionData";
import { JavaQuestionData } from "./JavaQuestionData";
import "./Styles.css";

const Quiz = ({ quizType }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [showResult, setShowResult] = useState(false);
  const [score, setScore] = useState(0);
  const [selectedAnswer, setSelectedAnswer] = useState(null);
  const [timeLeft, setTimeLeft] = useState(50); // default time left in seconds

  console.log("the value is : " + quizType);

  const questions = quizType === "java" ? JavaQuestionData : ReactQuestionData;

  useEffect(() => {
    if (timeLeft === 0) {
      handleNextClick();
      setTimeLeft(50); // reset timer for next question
    }
    const timer = setInterval(() => {
      setTimeLeft(timeLeft - 1);
    }, 1000);
    return () => clearInterval(timer);
  }, [timeLeft]);

  const handleNextClick = () => {
    if (selectedAnswer === null) {
      alert("Please select an answer");
      return;
    }

    if (currentIndex < questions.length - 1) {
      setCurrentIndex(prevIndex => prevIndex + 1);
      setSelectedAnswer(null);
      setTimeLeft(50); // reset timer for next question
    } else {
      setShowResult(true);
    }
  };

  const handleAnswerChange = event => {
    setSelectedAnswer(parseInt(event.target.value));
  };

  const handleRestartClick = () => {
    setShowResult(false);
    setCurrentIndex(0);
    setScore(0);
    setSelectedAnswer(null);
    setTimeLeft(50);
  };

  const renderResult = () => {
    return (
      <Card>
        <Card.Body>
          <Card.Title>Quiz Result</Card.Title>
          <Card.Text>
            You got {score} out of {questions.length} correct!
          </Card.Text>
          <Button variant="primary" onClick={handleRestartClick}>
            Restart Quiz
          </Button>
        </Card.Body>
      </Card>
    );
  };

  const renderQuiz = () => {
    const question = questions[currentIndex];

    return (
      <Card>
        <Card.Body>
          <Card.Title>
            Question {currentIndex + 1} (Time left: {timeLeft}s)
          </Card.Title>
          <Card.Text>{question.question}</Card.Text>
          <Form.Group>
            {question.answers.map((answer, index) => (
              <Form.Check
                type="radio"
                label={answer}
                name="answer"
                key={index}
                value={index}
                onChange={handleAnswerChange}
                checked={selectedAnswer === index}
              />
            ))}
          </Form.Group>
          <Button variant="primary" onClick={handleNextClick}>
            {currentIndex < questions.length - 1 ? "Next" : "Finish"}
          </Button>
        </Card.Body>
      </Card>
    );
  };

  return <>{showResult ? renderResult() : renderQuiz()}</>;
};

export default Quiz; */

/*import React, { useState } from "react";
import { Form, Button, Card } from "react-bootstrap";
import { ReactQuestionData } from "./ReactQuestionData";
import { JavaQuestionData } from "./JavaQuestionData";
import "./Styles.css";

const Quiz = ({ quizType }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [showResult, setShowResult] = useState(false);
  const [score, setScore] = useState(0);
  const [selectedAnswer, setSelectedAnswer] = useState(null);

  console.log("the value is : " + quizType);

  const questions = quizType === "java" ? JavaQuestionData : ReactQuestionData;

  const handleNextClick = () => {
    if (selectedAnswer === null) {
      alert("Please select an answer");
      return;
    }

    if (currentIndex < questions.length - 1) {
      setCurrentIndex(prevIndex => prevIndex + 1);
      setSelectedAnswer(null);
    } else {
      setShowResult(true);
    }
  };

  const handleAnswerChange = event => {
    setSelectedAnswer(parseInt(event.target.value));
  };

  const handleRestartClick = () => {
    setShowResult(false);
    setCurrentIndex(0);
    setScore(0);
    setSelectedAnswer(null);
  };

  const renderResult = () => {
    return (
      <Card>
        <Card.Body>
          <Card.Title>Quiz Result</Card.Title>
          <Card.Text>
            You got {score} out of {questions.length} correct!
          </Card.Text>
          <Button variant="primary" onClick={handleRestartClick}>
            Restart Quiz
          </Button>
        </Card.Body>
      </Card>
    );
  };

  const renderQuiz = () => {
    const question = questions[currentIndex];

    return (
      <Card>
        <Card.Body>
          <Card.Title>Question {currentIndex + 1}</Card.Title>
          <Card.Text>{question.question}</Card.Text>
          <Form.Group>
            {question.answers.map((answer, index) => (
              <Form.Check
                type="radio"
                label={answer}
                name="answer"
                key={index}
                value={index}
                onChange={handleAnswerChange}
                checked={selectedAnswer === index}
              />
            ))}
          </Form.Group>
          <Button variant="primary" onClick={handleNextClick}>
            {currentIndex < questions.length - 1 ? "Next" : "Finish"}
          </Button>
        </Card.Body>
      </Card>
    );
  };

  return <>{showResult ? renderResult() : renderQuiz()}</>;
};

export default Quiz;

*/
