import React from "react";
import PropTypes from "prop-types";

const Description = ({ title, description, buttonText, onClick }) => {
  return (
    <div className="container mt-5">
      <div className="row">
        <div className="col-md-8 offset-md-2">
          <h2 className="text-center">{title}</h2>
          <p className="lead text-center">{description}</p>
          <div className="text-center">
            <button className="btn btn-primary" onClick={onClick}>
              {buttonText}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

Description.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  buttonText: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired
};

export default Description;
