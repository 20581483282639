import React, { Component } from "react";

class BlogSideBarRight extends Component {
  render() {
    return (
      <div>
        <div>
          <h4>Popular Blogs</h4>
          <ul>
            <a href="/consul">what is consul ?</a>
          </ul>
          <ul>
            <a href="/cdn">what is a cdn ?</a>
          </ul>
          <ul>
            <a href="/git">Lets Understand GIT.</a>
          </ul>
          <ul>
            <a href="/projects">sample Projects for you</a>
          </ul>
          <ul>
            <a href="/cache">What is a Cache ?</a>
          </ul>
          <ul>
            <a href="/blind75">
              {" "}
              Must know 75 Big Tech coding interview questions
            </a>
          </ul>
        </div>
      </div>
    );
  }
}

export default BlogSideBarRight;
