import React, { Component } from "react";
import BlogSideBarRight from "../blogs/BlogSideBarRight";
import ProfilePic from "../../images/profile/profile-pic.jpeg";

class InterviewProcess extends Component {
  render() {
    return (
      <div className="container-fluid">
        <div className="row flex-xl-nowrap">
          <div className="col-6 col-md-2 clo-xl-2 bd-sidebar mb-2 m4">
            <div></div>
            <img
              src={ProfilePic}
              class="rounded-circle w-50 p-2 m-4"
              alt="Rabin's Profile"
            />
            <div></div>
            <a href="https://www.linkedin.com/in/rabinpantha">
              Rabin Pantha , IT Engineer , MSCS
            </a>
            <div class="text-center">Sr. Software Engineer, Walgreens</div>
          </div>
          <main
            className="col-12 col-md-9 col-xl-8 py-md-3 pl-md-5 bd-content"
            role="main"
          >
            <div className="col-xs-12">
              <h2 className="m-5">
                What are the interview process for a IT/Programming interview ?
              </h2>
              <p className="text-left lead text-justify text-break">
                The programming job interview process typically consists of
                several stages:
              </p>

              <p className="text-left lead text-justify text-break">
                <ol>
                  <li>
                    Resume screening: This is the initial stage where the hiring
                    manager or HR representative reviews your resume to
                    determine if you are a good fit for the role.
                  </li>
                  <li>
                    Phone screening: If you pass the resume screening, you'll be
                    contacted for a phone screening, where the interviewer will
                    ask you basic questions about your experience, skills, and
                    qualifications.
                  </li>
                  <li>
                    Technical screening: This stage involves a technical
                    interview, where you will be asked questions related to your
                    technical skills, algorithms, and data structures. This may
                    be done over the phone or in-person.
                  </li>
                  <li>
                    On-site interview: If you pass the technical screening, you
                    will be invited for an on-site interview, which may include
                    multiple rounds of technical interviews, a coding challenge,
                    and a discussion with your potential team members.
                  </li>
                  <li>
                    Offer stage: If you perform well in the on-site interview,
                    you will receive an offer from the company.
                  </li>
                </ol>
              </p>
              <p className="text-left lead text-justify text-break">
                The interview process may vary depending on the company, the
                role, and the specific requirements, but the stages listed above
                are typical in most programming job interviews. It's important
                to prepare well for each stage, be confident and professional,
                and clearly articulate your skills and experience to increase
                your chances of success.
              </p>
            </div>
          </main>
          <div className="col-6 col-md-2 clo-xl-2 mb-2 border-left">
            <BlogSideBarRight />
          </div>
        </div>
      </div>
    );
  }
}

export default InterviewProcess;
