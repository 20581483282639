import React, { Component } from "react";
import ProfilePic from "../../images/profile/profile-pic.jpeg";

class ProfileRabin extends Component {
  render() {
    return (
      <div>
        <div></div>
        <img
          src={ProfilePic}
          class="rounded-circle w-50 p-2 m-4"
          alt="Rabin's Profile"
        />
        <div></div>
        <a href="https://www.linkedin.com/in/rabinpantha">
          Rabin Pantha , IT Engineer , MSCS
        </a>
        <div class="text-center">Sr. Software Engineer, Walgreens</div>
      </div>
    );
  }
}

export default ProfileRabin;
