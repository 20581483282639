import React, { Component } from "react";
import { Link } from "react-router-dom";
import ScrollButton from "../../ScrollButton";

class QnA extends Component {
  render() {
    return (
      <div className="TodoApp container">
        <div>
          <div className="container">
            <h1 class="text-center">Welcome to Interview Guide</h1>
            <h4>Overview</h4>
            <div class="text-justify">
              Dear Visitors, Welcome to our website! We are thrilled that you
              have taken the time to visit us and explore what we have to offer.
              Our goal is to provide you with valuable information and resources
              that will help you achieve your goals and improve your life in
              meaningful ways. Whether you're here to learn about a specific
              topic or just browsing, we hope that you find the information on
              our site helpful and inspiring.
            </div>
            <div>
              We take great pride in our commitment to delivering the highest
              quality content, and we are always striving to improve our website
              and the services we offer. Our team of experts works tirelessly to
              research, write and publish articles and resources that are
              accurate, relevant and up-to-date. We believe that knowledge is
              power, and we are dedicated to empowering you with the information
              you need to make informed decisions and take control of your life.
              Thank you for choosing to spend your time with us, and we look
              forward to serving you in the future.
            </div>
            <br />
            <div class="text-center">
              <div>
                <a
                  type="button"
                  class="btn-floating btn-fb p-1 btn btn-outline-secondary"
                >
                  <Link className="nav-link" to="/java">
                    Java
                  </Link>
                </a>
                <a
                  type="button"
                  class="btn-floating btn-fb p-1 btn btn-outline-secondary"
                >
                  <Link className="nav-link" to="/kafka">
                    Kafka
                  </Link>
                </a>
                <a
                  type="button"
                  class="btn-floating btn-fb p-1 btn btn-outline-secondary"
                >
                  <Link className="nav-link" to="/cassandra">
                    Cassandra
                  </Link>
                </a>
                <a
                  type="button"
                  class="btn-floating btn-fb p-1 btn btn-outline-secondary"
                >
                  <Link className="nav-link" to="/sql">
                    SQL
                  </Link>
                </a>
                <a
                  type="button"
                  class="btn-floating btn-fb p-1 btn btn-outline-secondary"
                >
                  <Link className="nav-link" to="/javascript">
                    JavaScript
                  </Link>
                </a>
                <a
                  type="button"
                  class="btn-floating btn-fb p-1 btn btn-outline-secondary"
                >
                  <Link className="nav-link" to="/react">
                    React
                  </Link>
                </a>
                <a
                  type="button"
                  class="btn-floating btn-fb p-1 btn btn-outline-secondary"
                >
                  <Link className="nav-link" to="/docker">
                    Docker
                  </Link>
                </a>
                <a
                  type="button"
                  class="btn-floating btn-fb p-1 btn btn-outline-secondary"
                >
                  <Link className="nav-link" to="/terraform">
                    Terraform
                  </Link>
                </a>
              </div>
              <div>
                <a
                  type="button"
                  class="btn-floating btn-fb p-1 btn btn-outline-secondary"
                >
                  <Link className="nav-link" to="/spring">
                    Spring
                  </Link>
                </a>
                <a
                  type="button"
                  class="btn-floating btn-fb p-1 btn btn-outline-secondary"
                >
                  <Link className="nav-link" to="/hibernate">
                    Hibernate
                  </Link>
                </a>
                <a
                  type="button"
                  class="btn-floating btn-fb p-1 btn btn-outline-secondary"
                >
                  <Link className="nav-link" to="/cosmos">
                    Cosmos
                  </Link>
                </a>
                <a
                  type="button"
                  class="btn-floating btn-fb p-1 btn btn-outline-secondary"
                >
                  <Link className="nav-link" to="/serviceBus">
                    Service Bus
                  </Link>
                </a>
                <a
                  type="button"
                  class="btn-floating btn-fb p-1 btn btn-outline-secondary"
                >
                  <Link className="nav-link" to="/amazonWebServices">
                    Amazon Web Service
                  </Link>
                </a>
                <a
                  type="button"
                  class="btn-floating btn-fb p-1 btn btn-outline-secondary"
                >
                  <Link className="nav-link" to="/azure">
                    Azure
                  </Link>
                </a>
                <a
                  type="button"
                  class="btn-floating btn-fb p-1 btn btn-outline-secondary"
                >
                  <Link className="nav-link" to="/kubernetes">
                    Kubernetes
                  </Link>
                </a>
              </div>
              <div>
                <a
                  type="button"
                  class="btn-floating btn-fb p-1 btn btn-outline-secondary"
                >
                  <Link className="nav-link" to="/angular">
                    Angular
                  </Link>
                </a>
                <a
                  type="button"
                  class="btn-floating btn-fb p-1 btn btn-outline-secondary"
                >
                  <Link className="nav-link" to="/java8features">
                    Java 8 features
                  </Link>
                </a>
                <a
                  type="button"
                  class="btn-floating btn-fb p-1 btn btn-outline-secondary"
                >
                  <Link className="nav-link" to="/dsa">
                    Data Structure and Algorithm
                  </Link>
                </a>
                <a
                  type="button"
                  class="btn-floating btn-fb p-1 btn btn-outline-secondary"
                >
                  <Link className="nav-link" to="/mongoDB">
                    Mongo DB
                  </Link>
                </a>
                <a
                  type="button"
                  class="btn-floating btn-fb p-1 btn btn-outline-secondary"
                >
                  <Link className="nav-link" to="/elasticSearch">
                    Elastic Search
                  </Link>
                </a>
                <a
                  type="button"
                  class="btn-floating btn-fb p-1 btn btn-outline-secondary"
                >
                  <Link className="nav-link" to="/designPatterns">
                    Design Patterns
                  </Link>
                </a>
              </div>
              <div>
                <a
                  type="button"
                  class="btn-floating btn-fb p-1 btn btn-outline-secondary"
                >
                  <Link className="nav-link" to="/sdlc">
                    Software Development Life Cycle
                  </Link>
                </a>
                <a
                  type="button"
                  class="btn-floating btn-fb p-1 btn btn-outline-secondary"
                >
                  <Link className="nav-link" to="/microservices">
                    Microservices
                  </Link>
                </a>
                <a
                  type="button"
                  class="btn-floating btn-fb p-1 btn btn-outline-secondary"
                >
                  <Link className="nav-link" to="/restAPI">
                    Rest API
                  </Link>
                </a>
                <a
                  type="button"
                  class="btn-floating btn-fb p-1 btn btn-outline-secondary"
                >
                  <Link className="nav-link" to="/systemDesign">
                    System Design
                  </Link>
                </a>
                <a
                  type="button"
                  class="btn-floating btn-fb p-1 btn btn-outline-secondary"
                >
                  <Link className="nav-link" to="/codingRound">
                    Coding Round
                  </Link>
                </a>
                <a
                  type="button"
                  class="btn-floating btn-fb p-1 btn btn-outline-secondary"
                >
                  <Link className="nav-link" to="/behavioral">
                    Behavioral Questions
                  </Link>
                </a>
              </div>
            </div>
          </div>
          <br />
          <div class="card-deck mb-4">
            <div class="card">
              <img
                class="card-img-top"
                src="https://media.geeksforgeeks.org/wp-content/cdn-uploads/20201111215809/How-to-Become-a-Front-End-Developer-in-2020.png"
                alt="Card image cap"
              />
              <div class="card-body">
                <h5 class="card-title">Front End Developement</h5>
                <p class="card-text">
                  Front-end development involves the design and development of
                  user-facing parts of web applications and websites. It
                  encompasses a range of skills such as HTML, CSS, JavaScript,
                  and various front-end frameworks and libraries. The role of a
                  front-end developer is to bring a website or application to
                  life, making it visually appealing, user-friendly.
                </p>
                <p class="card-text">
                  <small class="text-muted">Last updated 3 days ago</small>
                </p>
              </div>
            </div>
            <div class="card">
              <img
                class="card-img-top"
                src="https://www.temok.com/blog/wp-content/uploads/2020/04/backend2.jpg"
                alt="Card image cap"
              />
              <div class="card-body">
                <h5 class="card-title">Back End Development</h5>
                <p class="card-text">
                  Back-end development involves the creation and maintenance of
                  the server-side components of web applications and websites.
                  This includes setting up and managing databases, writing
                  server-side code using programming languages like Python and
                  Java. Back-end developers play a critical role in ensuring the
                  stability, security, and scalability of web applications.
                </p>
                <p class="card-text">
                  <small class="text-muted">Last updated 3 days ago</small>
                </p>
              </div>
            </div>
            <div class="container card">
              <img
                class="card-img-top"
                src="https://marvel-b1-cdn.bc0a.com/f00000000236551/dt-cdn.net/wp-content/uploads/2021/07/13429_ILL_DevOpsLoop.png"
                alt="Card image cap"
              />
              <div class="card-body">
                <h5 class="card-title">DevOps | System Addays</h5>
                <p class="card-text">
                  DevOps emphasizes collaboration and communication between
                  development and operations teams. It aims to automate the
                  software development process, from development to testing,
                  deployment, and production. DevOps engineers are responsible
                  for implementing and maintaining the tools and processes that
                  enable this collaboration and automation.
                </p>
                <p class="card-text">
                  <small class="text-muted">Last updated 3 days ago</small>
                </p>
              </div>
            </div>
          </div>
          <main role="main" class="container">
            <div class="row">
              <div class="col-md-8 blog-main">
                <h3 class="pb-3 mb-4 font-italic border-bottom">
                  From the Developer
                </h3>

                <div class="blog-post">
                  <h2 class="blog-post-title">
                    Why Information and technology ?
                  </h2>
                  <p class="blog-post-meta">
                    January 1, 2023 by <a href="#">Khusbu</a>
                  </p>

                  <p>
                    Programming and software development are fields that are
                    constantly evolving and growing in scope. With the
                    increasing demand for technology in nearly every industry,
                    the need for skilled programmers and software developers has
                    never been higher. The scope of programming and software
                    development is vast and encompasses a wide range of tasks,
                    from designing and coding new software applications to
                    updating and maintaining existing ones.
                  </p>
                  <hr />
                  <p>
                    In recent years, the rise of mobile devices and the internet
                    has created a whole new world of opportunities for
                    programmers and software developers. From creating mobile
                    apps to developing complex web-based systems, the
                    possibilities are endless. Additionally, the field of
                    artificial intelligence and machine learning has opened up
                    new areas of growth, as more and more companies seek to
                    integrate these technologies into their products and
                    services.
                  </p>
                  <blockquote>
                    <p>
                      Another area of growth in programming and software
                      development is cybersecurity. As the reliance on
                      technology continues to increase,{" "}
                      <strong>
                        the need for secure systems and applications has become
                        a top priority.
                      </strong>{" "}
                      From developing secure software to protecting against
                      cyber attacks, programmers and software developers are
                      playing a critical role in protecting businesses and
                      individuals from the ever-present threat of cybercrime.
                    </p>
                  </blockquote>
                  <p>
                    In conclusion, the scope of programming and software
                    development is diverse, dynamic, and constantly expanding.
                    With new technologies and advancements emerging every day,
                    there is no limit to what can be achieved in this exciting
                    and rewarding field.
                  </p>
                </div>
              </div>

              <aside class="col-md-4 blog-sidebar">
                <div class="p-3 mb-3 bg-light rounded">
                  <h4 class="font-italic">About</h4>
                  <p class="mb-0">
                    "We appreciate the trust you have placed in us by visiting
                    our website and we hope to{" "}
                    <em>inspire and positively impact </em>
                    your life through the information we provide."
                  </p>
                </div>

                <div class="p-3">
                  <h4 class="font-italic">Archives</h4>
                  <ol class="list-unstyled mb-0">
                    <li>
                      <a href="#">March 2014</a>
                    </li>
                    <li>
                      <a href="#">February 2014</a>
                    </li>
                    <li>
                      <a href="#">January 2014</a>
                    </li>
                    <li>
                      <a href="#">December 2013</a>
                    </li>
                    <li>
                      <a href="#">November 2013</a>
                    </li>
                    <li>
                      <a href="#">October 2013</a>
                    </li>
                    <li>
                      <a href="#">September 2013</a>
                    </li>
                    <li>
                      <a href="#">August 2013</a>
                    </li>
                    <li>
                      <a href="#">July 2013</a>
                    </li>
                    <li>
                      <a href="#">June 2013</a>
                    </li>
                    <li>
                      <a href="#">May 2013</a>
                    </li>
                    <li>
                      <a href="#">April 2013</a>
                    </li>
                  </ol>
                </div>

                <div class="p-3">
                  <h4 class="font-italic">Elsewhere</h4>
                  <ol class="list-unstyled">
                    <li>
                      <a href="#">GitHub</a>
                    </li>
                    <li>
                      <a href="#">Twitter</a>
                    </li>
                    <li>
                      <a href="#">Facebook</a>
                    </li>
                  </ol>
                </div>
              </aside>
            </div>
          </main>
        </div>
        <ScrollButton />
      </div>
    );
  }
}

export default QnA;
