import React, { Component } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import AuthenticatedRoute from "./AuthenticatedRoute.jsx";
import LoginComponent from "./LoginComponent.jsx";
import ListTodosComponent from "./ListTodosComponent.jsx";
import ErrorComponent from "./ErrorComponent.jsx";
import HeaderComponent from "./HeaderComponent.jsx";
import FooterComponent from "./FooterComponent.jsx";
import LogoutComponent from "./LogoutComponent.jsx";
import WelcomeComponent from "./WelcomeComponent.jsx";
import TodoComponent from "./TodoComponent.jsx";
import QnAJava from "../questionanswer/QnAJava.jsx";
import QnA from "../questionanswer/QnA.jsx";
import QnAReact from "../questionanswer/QnAReact.jsx";
import QnAKafka from "../questionanswer/QnAKafka.jsx";
import QnACassandra from "../questionanswer/QnACassandra.jsx";
import QnASQL from "../questionanswer/QnASQL.jsx";
import AddQuestion from "../questionanswer/AddQuestion.jsx";
import Projects from "../questionanswer/Projects.jsx";
import InterviewProcess from "../questionanswer/InterviewProcess.jsx";
import PrepareForInterview from "../questionanswer/PrepareForInterview.jsx";
import QnAPaginationAll from "../questionanswer/QnAPaginationAll.jsx";
import StreamAPIBlog from "../blogs/java/StreamAPIBlog.jsx";
import PrivacyPolicyMain from "../privacy/PrivacyPolicyMain.jsx";
import AddressBookPrivacyPolicy from "../privacy/AddressBookPrivacyPolicy.jsx";
import VocabularyAppPrivacyPolicy from "../privacy/VocabularyAppPrivacyPolicy.jsx";
import InterviewGuidePrivacyPolicy from "../privacy/InterviewGuidePrivacyPolicy.jsx";
import AboutUs from "../questionanswer/AboutUs.jsx";
import AmazonProducts from "../affiliates/AmazonProducts";
import WalmartProducts from "../affiliates/WalmartProducts";
import QnASpring from "../questionanswer/QnASpring.jsx";
import QnAHibernate from "../questionanswer/QnAHibernate.jsx";
import QnAJavaScript from "../questionanswer/QnAJavaScript.jsx";
import QnAJava8Features from "../questionanswer/QnAJava8Features.jsx";
import DesignPatterns from "../questionanswer/DesignPatterns.jsx";
import QnAAmazonWebServices from "../questionanswer/QnAAmazonWebServices.jsx";
import QnADataStructureAndAlgorithm from "../questionanswer/QnADataStructureAndAlgorithm.jsx";
import QnADocker from "../questionanswer/QnADocker.jsx";
import QnAKubernetes from "../questionanswer/QnAKubernetes.jsx";
import QnATerraform from "../questionanswer/QnATerraform.jsx";
import QnAAzure from "../questionanswer/QnAAzure.jsx";
import QnAMongoDB from "../questionanswer/QnAMongoDB.jsx";
import QnAElasticSearch from "../questionanswer/QnAElasticSearch.jsx";
import QnAAngular from "../questionanswer/QnAAngular.jsx";
import QnABehavioral from "../questionanswer/QnABehavioral.jsx";
import QnAMicroservices from "../questionanswer/QnAMicroservices.jsx";
import QnArestAPI from "../questionanswer/QnArestAPI.jsx";
import QnASystemDesign from "../questionanswer/QnASystemDesign.jsx";
import QnACodingRound from "../questionanswer/QnACodingRound.jsx";
import QnABlind75 from "../questionanswer/QnABlind75.jsx";
import ConsulBlog from "../blogs/ConsulBlog.jsx";
import CDNBlog from "../blogs/CDNBlog.jsx";
import Algorithm from "../algorithms/Algorithm.jsx";
import QnASDLC from "../questionanswer/QnASDLC.jsx";
import GitBlog from "../blogs/GitBlog.jsx";
import AbilityTest from "../ability-test/AbilityTest.jsx";
import Blogs from "../blogs/Blogs.jsx";
import QnAServiceBus from "../questionanswer/QnAServiceBus.jsx";
import CacheBlog from "../blogs/CacheBlog.jsx";
import AndroidApp from "../privacy/AndroidApp.jsx";
import Sidebar from "../navBar/Sidebar";
import QnACosmos from "../questionanswer/QnACosmos.jsx";
import Quiz from "../ability-test/Quiz.jsx";
import QuizApp from "../ability-test/QuizApp.jsx";
import QnAJavalin from "../questionanswer/QnAJavalin.jsx";
import JsonSearch from "../questionanswer/search/JsonSearch.jsx";

class TodoApp extends Component {
  render() {
    return (
      <div className="TodoApp">
        <Router>
          <>
            <HeaderComponent />

            <Switch>
              <Route path="/" exact component={QnA} />
              <Route path="/login" component={LoginComponent} />
              <AuthenticatedRoute
                path="/welcome/:name"
                component={WelcomeComponent}
              />
              <AuthenticatedRoute path="/todos/:id" component={TodoComponent} />
              <AuthenticatedRoute
                path="/todos"
                component={ListTodosComponent}
              />
              <AuthenticatedRoute path="/logout" component={LogoutComponent} />
              <AuthenticatedRoute
                path="/addQuestion/:id"
                component={AddQuestion}
              />
              <Route path="/home" component={QnA} />
              <Route path="/questions" component={QnAJava} />
              <Route path="/java" component={QnAJava} />
              <Route path="/kafka" component={QnAKafka} />
              <Route path="/cassandra" component={QnACassandra} />
              <Route path="/react" component={QnAReact} />
              <Route path="/sql" component={QnASQL} />
              <Route path="/spring" component={QnASpring} />
              <Route path="/hibernate" component={QnAHibernate} />
              <Route path="/javascript" component={QnAJavaScript} />
              <Route path="/java8features" component={QnAJava8Features} />
              <Route path="/designPatterns" component={DesignPatterns} />
              <Route path="/blind75" component={QnABlind75} />
              <Route path="/consul" component={ConsulBlog} />
              <Route path="/cdn" component={CDNBlog} />
              <Route path="/algorithms" component={Algorithm} />
              <Route path="/sdlc" component={QnASDLC} />
              <Route path="/git" component={GitBlog} />
              <Route path="/abilityTest" component={AbilityTest} />
              <Route path="/blogs" component={Blogs} />
              <Route path="/serviceBus" component={QnAServiceBus} />
              <Route path="/cache" component={CacheBlog} />
              <Route path="/androidApps" component={AndroidApp} />
              *<Route path="/quiz" component={Quiz} />
              <Route path="/quizApp" component={QuizApp} />
              <Route
                path="/amazonWebServices"
                component={QnAAmazonWebServices}
              />
              <Route path="/dsa" component={QnADataStructureAndAlgorithm} />
              <Route path="/kubernetes" component={QnAKubernetes} />
              <Route path="/docker" component={QnADocker} />
              <Route path="/terraform" component={QnATerraform} />
              <Route path="/azure" component={QnAAzure} />
              <Route path="/mongoDB" component={QnAMongoDB} />
              <Route path="/elasticSearch" component={QnAElasticSearch} />
              <Route path="/angular" component={QnAAngular} />
              <Route path="/microservices" component={QnAMicroservices} />
              <Route path="/behavioral" component={QnABehavioral} />
              <Route path="/restAPI" component={QnArestAPI} />
              <Route path="/systemDesign" component={QnASystemDesign} />
              <Route path="/codingRound" component={QnACodingRound} />
              <Route path="/cosmos" component={QnACosmos} />
              <Route path="/javalin" component={QnAJavalin} />
              {/* <Route path="/selenium" component={QnASelenium} /> */}
              <Route path="/projects" component={Projects} />
              <Route path="/interviewProcess" component={InterviewProcess} />
              <Route path="/all" component={QnAPaginationAll} />
              <Route path="/privacyPolicyMain" component={PrivacyPolicyMain} />
              <Route path="/aboutUs" component={AboutUs} />
              <Route path="/search" component={JsonSearch} />
              <Route
                path="/addressBookPrivacyPolicy"
                component={AddressBookPrivacyPolicy}
              />
              <Route
                path="/vocabularyAppPrivacyPolicy"
                component={VocabularyAppPrivacyPolicy}
              />
              <Route
                path="/interviewGuidePrivacyPolicy"
                component={InterviewGuidePrivacyPolicy}
              />
              <Route path="/blog/java/streamAPI" component={StreamAPIBlog} />
              <Route
                path="/prepareForInterview"
                component={PrepareForInterview}
              />
              <Route path="/amazonProducts" component={AmazonProducts} />
              <Route path="/walmartProducts" component={WalmartProducts} />
              <Route component={ErrorComponent} />
            </Switch>
            <Sidebar />
            <FooterComponent />
          </>
        </Router>
        {/*<LoginComponent/>
                <WelcomeComponent/>*/}
      </div>
    );
  }
}

export default TodoApp;
