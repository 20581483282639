import React, { Component } from "react";

class AlgorithmSideBarRight extends Component {
  render() {
    return (
      <div>
        <div>
          <h3>Popular Algorithm</h3>
          <ul>
            <a href="#">Sorting Algorithm</a>
          </ul>
          <ul>
            <a href="#">Searching Algorithm</a>
          </ul>
          <ul>
            <a href="#">Graph Algorithm</a>
          </ul>
          <ul>
            <a href="#">Backtracking Algorithm</a>
          </ul>
          <ul>
            <a href="#">Tree Algorithm</a>
          </ul>
          <ul>
            <a href="#">String Algorithm</a>
          </ul>
          <ul>
            <a href="#">Hashing Algorithm</a>
          </ul>
        </div>
      </div>
    );
  }
}

export default AlgorithmSideBarRight;
