//import ListQnA from "./ListQnA";
import React from "react";
import blog from "./BlogJSON/GitJSON.json";
import BlogSideBarRight from "./BlogSideBarRight";
import ScrollButton from "../../ScrollButton";
import ProfilePic from "../../images/profile/profile-pic.jpeg";

const GitBlog = () => {
  return (
    <div className="container-fluid">
      <div className="row flex-xl-nowrap">
        <div className="col-6 col-md-2 clo-xl-2 bd-sidebar mb-2 m4">
          <div></div>
          <img
            src={ProfilePic}
            class="rounded-circle w-50 p-2 m-4"
            alt="Rabin's Profile"
          />
          <div></div>
          <a href="https://www.linkedin.com/in/rabinpantha">
            Rabin Pantha , IT Engineer , MSCS
          </a>
          <div class="text-center">Sr. Software Engineer, Walgreens</div>
        </div>
        <main
          className="col-12 col-md-9 col-xl-8 py-md-3 pl-md-5 bd-content"
          role="main"
        >
          <div className="col-xs-12">
            <p className="text-left lead text-justify text-break">
              <ol>
                <h2 className="m-4">{blog.title}</h2>
                <div>
                  <p>{blog.introduction}</p>
                </div>
                <div>
                  <p className="text-left lead text-justify text-break">
                    <ol>
                      {blog.mcqs.map(mcqs => (
                        <li key={mcqs.id}>
                          <h5>{mcqs.question}</h5>
                          <p>{mcqs.answer.brief}</p>
                          <div>
                            <ol>
                              {mcqs.answer.bullets.map(bullet => (
                                <li>
                                  <p>{bullet}</p>
                                </li>
                              ))}
                            </ol>
                          </div>
                        </li>
                      ))}
                    </ol>
                    <p>{blog.gitCommands.intro}</p>
                    <ol type="i">
                      {blog.gitCommands.command.map(command => (
                        <li key={command.id}>
                          <code>'{command.name}'</code> : {command.description}
                        </li>
                      ))}
                    </ol>
                  </p>
                </div>
                <div>{blog.conclusion}</div>
              </ol>
            </p>
          </div>
        </main>
        <div className="col-6 col-md-2 clo-xl-2 mb-2 border-left">
          <BlogSideBarRight />
        </div>
      </div>
      <ScrollButton />
    </div>
  );
};

export default GitBlog;
