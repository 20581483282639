import React, { Component } from "react";
import beginnerQuestions from "./qnaJSONdata/QnAsql.json";
import experiencedQuestions from "./qnaJSONdata/QnAExperiencedSQL.json";
import QnAsqlScenarioProblems from "./qnaJSONdata/QnAsqlScenarioProblems.json";
import QnASideBarRight from "./QnASideBarRight";
import ScrollButton from "../../ScrollButton";

class QnASQL extends Component {
  render() {
    return (
      <div className="container-fluid">
        <div className="row flex-xl-nowrap">
          <div className="col-6 col-md-2 clo-xl-2 bd-sidebar mb-2 m4"></div>
          <main
            className="col-12 col-md-9 col-xl-8 py-md-3 pl-md-5 bd-content"
            role="main"
          >
            <div className="col-xs-12">
              <h2 className="m-5">
                Top SQL Interview Questions for Entry-Level Candidates
              </h2>
              <p className="text-left lead text-justify text-break">
                Discover the most commonly asked SQL interview questions for
                beginners and prepare for your next job interview:
              </p>

              <p className="text-left lead text-justify text-break">
                <ol>
                  {beginnerQuestions.map(question => (
                    <li key={question.id}>
                      <h5>{question.question}</h5>
                      <div>
                        <p>{question.answer}</p>
                      </div>
                    </li>
                  ))}
                </ol>
              </p>
            </div>
            <div className="col-xs-12">
              <h2 className="m-5">
                Top SQL Interview Questions for Experienced Candidates
              </h2>
              <p className="text-left lead text-justify text-break">
                Prepare for your next SQL job interview by reviewing these
                commonly asked interview questions for experienced candidates.
              </p>

              <p className="text-left lead text-justify text-break">
                <ol>
                  {experiencedQuestions.map(question => (
                    <li key={question.id}>
                      <h5>{question.question}</h5>
                      <div>
                        <p>{question.answer}</p>
                      </div>
                    </li>
                  ))}
                </ol>
              </p>
            </div>
            <div className="col-xs-12">
              <h2 className="m-5">
                Top Scenario-Based SQL Interview Questions to Prepare For
              </h2>
              <p className="text-left lead text-justify text-break">
                Get prepared for scenario-based SQL interview questions by
                reviewing these commonly asked problems in job interviews.
              </p>

              <p className="text-left lead text-justify text-break">
                <ol>
                  {QnAsqlScenarioProblems.map(question => (
                    <li key={question.id}>
                      <h5>{question.question}</h5>
                      <div>
                        <p>{question.answer}</p>
                        <pre>
                          <code>{question.code}</code>
                        </pre>
                      </div>
                    </li>
                  ))}
                </ol>
              </p>
            </div>
          </main>
          <div className="col-6 col-md-2 clo-xl-2 mb-2 border-left">
            <QnASideBarRight />
          </div>
        </div>
        <ScrollButton />
      </div>
    );
  }
}

export default QnASQL;
