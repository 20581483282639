import React, { Component } from "react";
import questionsJunior from "./qnaJSONdata/QnAJavascriptJunior.json";
import questionsSenior from "./qnaJSONdata/QnAJavascriptSenior.json";
import QnASideBarRight from "./QnASideBarRight";
import ScrollButton from "../../ScrollButton";

class QnAJavaScript extends Component {
  render() {
    return (
      <div className="container-fluid">
        <div className="row flex-xl-nowrap">
          <div className="col-6 col-md-2 clo-xl-2 bd-sidebar mb-2 m4"></div>
          <main
            className="col-12 col-md-9 col-xl-8 py-md-3 pl-md-5 bd-content"
            role="main"
          >
            <div className="col-xs-12">
              <h2>JavaScript Interview Questions for Junior Developer</h2>
              <p className="text-left lead text-justify text-break">
                JavaScript is a versatile and powerful scripting language that
                has become the de facto standard for front-end web development.
                With JavaScript, you can add dynamic and interactive features to
                your website, including animations, real-time updates, and
                interactive forms.
              </p>
              <p className="text-left lead text-justify text-break">
                JavaScript is a client-side language that runs directly on a
                user's browser, making it ideal for creating responsive and
                engaging web applications. JavaScript has a vast library of
                frameworks, libraries, and tools that simplify development and
                accelerate your time to market.
              </p>
              <p className="text-left lead text-justify text-break">
                JavaScript's popularity has exploded in recent years, with more
                developers using it than any other programming language.
                JavaScript is also versatile enough to be used on both the
                front-end and back-end of web development, making it an
                essential tool for full-stack developers.
              </p>
              <p className="text-left lead text-justify text-break">
                JavaScript is easy to learn and use, making it an excellent
                choice for beginner programmers. Its straightforward syntax,
                rich documentation, and vast community of developers make it
                easy to find resources and support when you need it.
              </p>
              <p className="text-left lead text-justify text-break">
                JavaScript is also essential for search engine optimization
                (SEO). With its ability to create dynamic content, optimize page
                speed, and implement user interactivity, JavaScript can
                significantly improve your website's search rankings and overall
                user experience. By mastering JavaScript, you can take your web
                development skills to the next level and achieve greater success
                in your career.
              </p>

              <p className="text-left lead text-justify text-break">
                The most popular Javascript interview questions for Junior
                Developers are as follows:
              </p>

              <p className="text-left lead text-justify text-break">
                <ol>
                  {questionsJunior.map(question => (
                    <li key={question.id}>
                      <h5>{question.question}</h5>
                      <div>
                        <p>{question.answer}</p>
                      </div>
                    </li>
                  ))}
                </ol>
              </p>
            </div>
            <div className="col-xs-12">
              <h2 className="m-5">
                JavaScript Interview Questions for Senior Developer
              </h2>
              <p className="text-left lead text-justify text-break">
                The most popular Javascript interview questions for
                Senior/Experienced Developers are as follows:
              </p>

              <p className="text-left lead text-justify text-break">
                <ol>
                  {questionsSenior.map(question => (
                    <li key={question.id}>
                      <h5>{question.question}</h5>
                      <div>
                        <p>{question.answer}</p>
                      </div>
                    </li>
                  ))}
                </ol>
              </p>
            </div>
          </main>
          <div className="col-6 col-md-2 clo-xl-2 mb-2 border-left">
            <QnASideBarRight />
          </div>
        </div>
        <ScrollButton />
      </div>
    );
  }
}

export default QnAJavaScript;
