import React, { Component } from "react";
import BookRecommendation from "../extra-materials/BookRecommendation";
import "./SideBar.css";

class QnASideBarRight extends Component {
  render() {
    return (
      <div className="sidebar">
        <div>
          <h4>Interview Questions</h4>
          <ul>
            <a href="/java">Java</a>
          </ul>
          <ul>
            <a href="/java8features">Java 8</a>
          </ul>
          <ul>
            <a href="/spring">Spring</a>
          </ul>
          <ul>
            <a href="/hibernate">Hibernate</a>
          </ul>
          <ul>
            <a href="/react">React JS</a>
          </ul>
          <ul>
            <a href="/sql">SQL</a>
          </ul>
          <ul>
            <a href="/mongoDB">Mongo DB</a>
          </ul>
          <ul>
            <a href="/javascript">Javascript</a>
          </ul>
          <ul>
            <a href="/kafka">Kafka</a>
          </ul>
          <ul>
            <a href="/designPatterns">Design Patterns</a>
          </ul>
          <ul>
            <a href="/microservices">Microservices</a>
          </ul>
          <ul>
            <a href="/restAPI">Rest API</a>
          </ul>
          <ul>
            <a href="/behavioral">Behavior Questions</a>
          </ul>
        </div>
        <BookRecommendation />
      </div>
    );
  }
}

export default QnASideBarRight;
