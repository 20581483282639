import React, { Component } from "react";
import { Form, FormControl } from "react-bootstrap";
import ScrollButton from "../../ScrollButton";
import QnASideBarRight from "../questionanswer/QnASideBarRight";
import BlogSideBarRight from "../blogs/BlogSideBarRight";

class Projects extends Component {
  render() {
    return (
      <div className="container-fluid">
        <div className="row flex-xl-nowrap">
          <div className="col-6 col-md-2 clo-xl-2 bd-sidebar mb-2 border-right border-bottom">
            <Form className="form-center p-2">
              <FormControl type="text" placeholder="Search" className="" />
            </Form>
            <BlogSideBarRight />
          </div>
          <main
            className="col-12 col-md-9 col-xl-8 py-md-3 pl-md-5 bd-content"
            role="main"
          >
            <h1>sample projects</h1>

            <div className="text-left lead text-justify text-break">
              <div>
                Welcome to our page where we showcase a collection of sample
                reference projects that serve as a source of inspiration for
                your own projects. Whether you are a beginner or an experienced
                developer, these projects are designed to help you learn new
                skills and techniques while providing you with a solid
                foundation to build upon.
              </div>
              <br />
              <div>
                Our list includes a diverse range of open source codes and
                projects that cover various domains such as web development,
                data science, machine learning, and more. You can use these
                projects to gain insight into the best coding practices and also
                contribute to the open source community by improving the
                projects.
              </div>
              <br />
              <div>
                We understand that the learning process is an ongoing journey
                and therefore, we are committed to providing you with more such
                sample projects in the future. We aim to help you enhance your
                coding skills, explore new technologies, and develop your own
                projects with confidence. Stay tuned for more exciting projects
                to come!
              </div>
            </div>
            <br />
            <div>===================</div>

            <li class="list-inline">
              <div className="row">
                <div className="col-sm-3">
                  <h3>
                    <a href="https://github.com/rbnpantha/movie">
                      Movie Project
                    </a>
                  </h3>
                </div>
                <div className="col-sm-9 text-justify">
                  <div>
                    Introducing our latest movie project developed using Java
                    Spring and REST API, following industry best practices for
                    software development and adhering to strict coding
                    standards. This project is a testament to our commitment to
                    delivering high-quality, well-structured code that is
                    scalable and efficient.
                  </div>
                  <br />
                  <div>
                    The Java Spring framework provides a robust foundation for
                    building complex web applications, and our REST API ensures
                    seamless communication between the front-end and back-end of
                    the application. In addition, we have implemented proper
                    modules to enhance the code organization and maintainability
                    of the project.
                  </div>
                  <br />
                  <div>
                    To create a seamless user experience, we have integrated a
                    JavaScript front-end that can consume the data and display
                    it in a user-friendly manner. This integration ensures that
                    the user can easily navigate through the application, search
                    for their favorite movies, and get relevant information in a
                    simple and intuitive way.
                  </div>
                  <br />
                  <div>
                    Overall, our movie project is a prime example of how Java
                    Spring and REST API can be used to build a powerful and
                    efficient application while following industry best
                    practices for software development. We are proud to showcase
                    this project and hope that it serves as a source of
                    inspiration for aspiring developers.
                  </div>
                </div>
              </div>
              <br />
              <div>===================</div>

              <div className="row">
                <div className="col-sm-3">
                  <h3>
                    <a href="https://github.com/rbnpantha/reservationsystem">
                      Reservation System
                    </a>
                  </h3>
                </div>
                <div className="col-sm-9 text-justify">
                  <div>
                    The hotel reservation system project is an innovative web
                    application that provides an end-to-end solution for
                    managing hotel room reservations. Built using Spring Boot
                    and Spring Data JPA, the project is a perfect example of how
                    modern technology can be used to develop efficient,
                    scalable, and robust web applications.
                  </div>
                  <br />
                  <div>
                    This system allows system admins to add, update or delete
                    rooms in the resort and users can easily book available
                    rooms. To ensure seamless communication between the
                    front-end and back-end, we have integrated RESTful web
                    services, which provide a standardized approach to
                    developing web services and are widely used in modern web
                    development.
                  </div>
                  <br />
                  <div>
                    As an added convenience, our system sends confirmation
                    emails to customers whenever they book a room. Additionally,
                    rooms are automatically made available once the reservation
                    period is over, ensuring that the system stays up-to-date
                    and room availability is accurately reflected.
                  </div>
                  <br />
                  <div>
                    To provide an enhanced user experience, we have implemented
                    a user profile feature that allows users to view their
                    booking history and other relevant information. Our project
                    uses a combination of technologies including Java, JSP,
                    HTML5, CSS3, JavaScript, Bootstrap, Eclipse, Git, Spring,
                    Hibernate, JSON, and more. We have also incorporated
                    well-managed scopes and CRUD data repositories to ensure the
                    project's scalability and maintainability.
                  </div>
                  <br />
                  <div>
                    Overall, the hotel reservation system project is an
                    excellent example of how modern technology can be used to
                    develop powerful and efficient web applications that meet
                    the needs of both administrators and users.
                  </div>
                </div>
              </div>
              <br />
              <div>===================</div>
              <div className="row">
                <div className="col-sm-3">
                  <h3>
                    <a href="https://github.com/rbnpantha/PalindromeApplication">
                      Palindrome App
                    </a>
                  </h3>
                </div>
                <div className="col-sm-9 text-justify">
                  <div>
                    Our Palindrome Application project is a simple yet powerful
                    tool for calculating the largest palindrome number between
                    two numbers provided by the user. The application is built
                    in JavaFX with the UI designed using the JavaFX Scene
                    Builder, providing an intuitive and user-friendly interface.
                  </div>
                  <br />
                  <div>
                    Using the application is a breeze - simply start the
                    application and input the upper and lower bounds of the
                    digit in the text box. Once you hit the search button, the
                    application will automatically calculate the largest
                    palindrome number between the two numbers.
                  </div>
                  <br />
                  <div>
                    Our application uses advanced algorithms to perform the
                    calculation quickly and accurately. If the user inputs
                    invalid numbers, the application will display an error
                    message, ensuring that the user knows exactly what went
                    wrong.
                  </div>
                  <div>
                    In summary, our Palindrome Application project is a great
                    example of how simple applications can solve complex
                    problems efficiently and effectively. Whether you're a
                    beginner or an experienced developer, this application is a
                    useful tool for anyone who needs to calculate the largest
                    palindrome number between two given numbers.
                  </div>
                </div>
              </div>
              <br />
              <div>===================</div>

              <div className="row">
                <div className="col-sm-3">
                  <h3>
                    <a href="https://github.com/rbnpantha/BlogApp/tree/master/blog">
                      Twitter-Bar Project
                    </a>
                  </h3>
                </div>
                <div className="col-sm-9 text-justify">
                  <div>
                    Our Twitter Bar project is a powerful and innovative web
                    application that provides real-time data on Twitter trends
                    and topics in a user-friendly interface. Built using a
                    combination of Java, JSP/Servlet, and other technologies,
                    this application is capable of extracting data using the
                    Twitter API and displaying relevant information in a
                    single-page interface.
                  </div>
                  <br />
                  <div>
                    The application displays a wide range of Twitter data,
                    including topics, related tweets, and trends, giving users a
                    complete view of what's happening on the platform.
                    Additionally, we have integrated Google Maps to provide
                    users with a more comprehensive view of the location of
                    tweets and trends.
                  </div>
                  <br />
                  <div>
                    Our project uses advanced technology, including Cookies,
                    Bitbucket, and Git, to provide a seamless user experience
                    and ensure the stability and scalability of the application.
                    The entire project is built in NetBeans, and we have used
                    Glassfish Server to ensure that the application runs
                    smoothly and efficiently.
                  </div>
                  <br />
                  <div>
                    Overall, our Twitter Bar project is an excellent example of
                    how advanced technology can be used to provide users with
                    real-time data and insights into Twitter trends and topics.
                    With its intuitive interface and powerful functionality,
                    this application is a must-have for anyone who wants to stay
                    up-to-date with the latest trends and topics on Twitter.
                  </div>
                  <br />
                </div>
              </div>
              <br />
            </li>
          </main>
          <div className="col-6 col-md-2 clo-xl-2 mb-2 border-left">
            <QnASideBarRight />
          </div>
        </div>
        <ScrollButton />
      </div>
    );
  }
}

export default Projects;
