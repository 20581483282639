import React, { useState } from "react";
import Angular from "../qnaJSONdata/QnAAngular.json";
import JuniorJava from "../qnaJSONdata/QnAJuniorJava.json";
import SeniorJava from "../qnaJSONdata/QnASeniorJava.json";
import DesignPattern from "../qnaJSONdata/DesignPatterns.json";
import AmazonWebServices from "../qnaJSONdata/QnAAmazonWebServices.json";
import QnAAzure from "../qnaJSONdata/QnAAzure.json";
import Behavioral from "../qnaJSONdata/QnABehavioral.json";
import Cassandra from "../qnaJSONdata/QnACassandra.json";
import Cosmos from "../qnaJSONdata/QnACosmos.json";
import DSA from "../qnaJSONdata/QnADataStructureAndAlgorithm.json";
import Docker from "../qnaJSONdata/QnADocker.json";
import ElasticSearch from "../qnaJSONdata/QnAElasticSearch.json";
import ExperiencedSQL from "../qnaJSONdata/QnAExperiencedSQL.json";
import Hibernate from "../qnaJSONdata/QnAHibernate.json";
import Java8Features from "../qnaJSONdata/QnAJava8Features.json";
import Javalin from "../qnaJSONdata/QnAJavalin.json";
import JavascriptJunior from "../qnaJSONdata/QnAJavascriptJunior.json";
import JavascriptSenior from "../qnaJSONdata/QnAJavascriptSenior.json";
import Kafka from "../qnaJSONdata/QnAKafka.json";
import Kubernetes from "../qnaJSONdata/QnAKubernetes.json";
import Microservices from "../qnaJSONdata/QnAMicroservices.json";
import MongoDB from "../qnaJSONdata/QnAMongoDB.json";
import ReactJS from "../qnaJSONdata/QnAReact.json";
import RestAPI from "../qnaJSONdata/QnArestAPI.json";
import ScenarioSpring from "../qnaJSONdata/QnAScenarioSpring.json";
import SDLC from "../qnaJSONdata/QnASDLC.json";
import ServiceBus from "../qnaJSONdata/QnAServiceBus.json";
import Spring from "../qnaJSONdata/QnASpring.json";
import SQL from "../qnaJSONdata/QnAsql.json";
import SQLScenarioProblem from "../qnaJSONdata/QnAsqlScenarioProblems.json";
import Terraform from "../qnaJSONdata/QnATerraform.json";

import "./JsonSearch.css";

// Define list of JSON files
const jsonFiles = [
  { id: 1, data: Angular },
  { id: 2, data: JuniorJava },
  { id: 3, data: SeniorJava },
  { id: 4, data: DesignPattern },
  { id: 5, data: AmazonWebServices },
  { id: 6, data: QnAAzure },
  { id: 7, data: Behavioral },
  { id: 8, data: Cassandra },
  { id: 9, data: Cosmos },
  { id: 10, data: DSA },
  { id: 11, data: Docker },
  { id: 12, data: ElasticSearch },
  { id: 13, data: ExperiencedSQL },
  { id: 14, data: Hibernate },
  { id: 15, data: Java8Features },
  { id: 16, data: Javalin },
  { id: 17, data: JavascriptJunior },
  { id: 18, data: JavascriptSenior },
  { id: 19, data: Kafka },
  { id: 20, data: Kubernetes },
  { id: 21, data: Microservices },
  { id: 22, data: MongoDB },
  { id: 23, data: ReactJS },
  { id: 24, data: RestAPI },
  { id: 25, data: ScenarioSpring },
  { id: 26, data: SDLC },
  { id: 27, data: ServiceBus },
  { id: 28, data: Spring },
  { id: 29, data: SQL },
  { id: 30, data: SQLScenarioProblem },
  { id: 31, data: Terraform }
];

const JsonSearch = () => {
  const [searchText, setSearchText] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [suggestions, setSuggestions] = useState([]);

  const handleSearchChange = event => {
    const value = event.target.value;
    setSearchText(value);

    // Generate autocomplete suggestions based on current input value
    const suggestions = [];
    for (const jsonFile of jsonFiles) {
      const matches = jsonFile.data.filter(
        item => item.question.toLowerCase().indexOf(value.toLowerCase()) !== -1
      );
      if (matches.length > 0) {
        suggestions.push(...matches.map(match => match.question));
      }
    }
    setSuggestions(suggestions);
  };

  const handleSearchSubmit = event => {
    event.preventDefault();

    const results = [];

    for (const jsonFile of jsonFiles) {
      const matches = jsonFile.data.filter(
        item =>
          item.question.toLowerCase().indexOf(searchText.toLowerCase()) !== -1
      );
      if (matches.length > 0) {
        results.push({ id: jsonFile.id, matches });
      }
    }

    setSearchResults(results);
  };

  const handleSuggestionClick = value => {
    setSearchText(value);
    setSuggestions([]);
  };

  return (
    <div className="element">
      <div className="json-search">
        <form onSubmit={handleSearchSubmit} className="form-center p-2">
          <input
            type="text"
            value={searchText}
            onChange={handleSearchChange}
            placeholder="Search for a question"
          />
          {suggestions.length > 0 && (
            <ul className="json-search-suggestions">
              {suggestions.slice(0, 5).map(suggestion => (
                <li
                  key={suggestion}
                  onClick={() => handleSuggestionClick(suggestion)}
                >
                  {suggestion}
                </li>
              ))}
            </ul>
          )}
          <button type="submit">Search</button>
        </form>
        {searchResults.length > 0 ? (
          <div className="json-search-results">
            {searchResults.map(result => (
              <div key={result.id} className="json-search-result">
                <h2>---------</h2>
                {result.matches.map(match => (
                  <div key={match.id} className="json-search-match">
                    <h3>{match.question}</h3>
                    <p>{match.answer}</p>
                    <p>{match.code}</p>
                  </div>
                ))}
              </div>
            ))}
          </div>
        ) : (
          <p className="json-search-no-results">No results found</p>
        )}
      </div>
    </div>
  );
};

export default JsonSearch;
