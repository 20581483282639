import React from "react";
import card1 from "../../images/img/card1.png";
import card2 from "../../images/img/card2.png";
import card3 from "../../images/img/card3.png";
function Services() {
  return (
    <div className="container">
      <h2 className="main-title text-center">SERVICES</h2>
      <div className="card-cover">
        <div className="col-md-12">
          <div className="row">
            <div className="col-md-4 mb-2">
              <div className="card">
                <br />

                <div className="text-center">
                  <img
                    alt="card-img"
                    src={card1}
                    className="text-center img-fluid"
                  />
                </div>
                <div className="text-center">
                  <h3 className="card-title">Web Development</h3>
                </div>
                <div className="p-3">
                  <p className="card-text">
                    Web development involves building and maintaining websites
                    using a combination of front-end technologies such as HTML,
                    CSS, and JavaScript, and back-end technologies such as
                    servers, databases, and APIs.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4 mb-2">
              <div className="card">
                <br />

                <div className="text-center">
                  <img
                    alt="card-img"
                    src={card2}
                    className="text-center img-fluid"
                  />
                </div>
                <div className="text-center">
                  <h3 className="card-title">E-Commerce Services</h3>
                </div>
                <div className="p-3">
                  <p className="card-text">
                    E-commerce refers to the buying and selling of products or
                    services over the internet through an online platform,
                    allowing consumers to shop 24/7 from the comfort of their
                    own home.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4 mb-2">
              <div className="card">
                <br />

                <div className="text-center">
                  <img
                    alt="card-img"
                    src={card3}
                    className="text-center img-fluid"
                  />
                </div>
                <div className="text-center">
                  <h3 className="card-title">Cyber Security</h3>
                </div>
                <div className="p-3">
                  <p className="card-text">
                    Cyber security is the practice of protecting
                    internet-connected systems, including hardware, software,
                    and data, from attack, damage, or unauthorized access.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Services;
