//import ListQnA from "./ListQnA";
import React from "react";
import questions from "./qnaJSONdata/QnADocker.json";
import QnASideBarRight from "./QnASideBarRight";
import ScrollButton from "../../ScrollButton";

const QnADocker = () => {
  return (
    <div className="container-fluid">
      <div className="row flex-xl-nowrap">
        <div className="col-6 col-md-2 clo-xl-2 bd-sidebar mb-2 m4"></div>
        <main
          className="col-12 col-md-9 col-xl-8 py-md-3 pl-md-5 bd-content"
          role="main"
        >
          <div className="col-xs-12">
            <h2 className="m-5">Docker Interview Questions</h2>
            <p className="text-left lead text-justify text-break">
              The most popular Docker interview questions are as follows:
            </p>

            <p className="text-left lead text-justify text-break">
              <ol>
                {questions.map(question => (
                  <li key={question.id}>
                    <h5>{question.question}</h5>
                    <div>
                      <p>{question.answer}</p>
                    </div>
                  </li>
                ))}
              </ol>
            </p>
          </div>
        </main>
        <div className="col-6 col-md-2 clo-xl-2 mb-2 border-left">
          <QnASideBarRight />
        </div>
      </div>
      <ScrollButton />
    </div>
  );
};

export default QnADocker;
