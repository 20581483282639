import React, { Component } from "react";
import DisplayContent from "./DisplayContent";
import { Form, FormControl } from "react-bootstrap";
import QnASideBarRight from "../questionanswer/QnASideBarRight";
import BlogSideBarRight from "../blogs/BlogSideBarRight";

class AndroidApp extends Component {
  render() {
    return (
      <div className="container-fluid">
        <div className="row flex-xl-nowrap">
          <div className="col-6 col-md-2 clo-xl-2 bd-sidebar mb-2 border-right border-bottom">
            <Form className="form-center p-2">
              <FormControl type="text" placeholder="Search" className="" />
            </Form>
            <BlogSideBarRight />
          </div>
          <main
            className="col-12 col-md-9 col-xl-8 py-md-3 pl-md-5 bd-content"
            role="main"
          >
            <DisplayContent />
          </main>
          <div className="col-6 col-md-2 clo-xl-2 mb-2 border-left">
            <QnASideBarRight />
          </div>
        </div>
      </div>
    );
  }
}

export default AndroidApp;
