import React from "react";
import * as FaIcons from "react-icons/fa";
import * as AiIcons from "react-icons/ai";
import * as IoIcons from "react-icons/io";
import * as RiIcons from "react-icons/ri";

export const SidebarData = [
  {
    title: "About Us",
    path: "/aboutUs",
    icon: <AiIcons.AiFillHome />
  },
  {
    title: "Interview Q&A",
    path: "/home",
    icon: <FaIcons.FaQuestionCircle />,
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,

    subNav: [
      {
        title: "Java",
        path: "/questions",
        icon: <IoIcons.IoIosPaper />,
        cName: "sub-nav"
      },
      {
        title: "Spring",
        path: "/spring",
        icon: <IoIcons.IoIosPaper />,
        cName: "sub-nav"
      },
      {
        title: "Hibernate",
        path: "/hibernate",
        icon: <IoIcons.IoIosPaper />
      },
      {
        title: "Javalin",
        path: "/javalin",
        icon: <IoIcons.IoIosPaper />
      },
      {
        title: "JavaScript",
        path: "/javascript",
        icon: <IoIcons.IoIosPaper />,
        cName: "sub-nav"
      },
      {
        title: "React",
        path: "/react",
        icon: <IoIcons.IoIosPaper />,
        cName: "sub-nav"
      },
      {
        title: "Angular",
        path: "/angular",
        icon: <IoIcons.IoIosPaper />
      },
      {
        title: "Kafka",
        path: "/kafka",
        icon: <IoIcons.IoIosPaper />,
        cName: "sub-nav"
      },
      {
        title: "Service Bus",
        path: "/serviceBus",
        icon: <IoIcons.IoIosPaper />,
        cName: "sub-nav"
      },
      {
        title: "SQL",
        path: "/sql",
        icon: <IoIcons.IoIosPaper />
      },
      {
        title: "Cassandra",
        path: "/cassandra",
        icon: <IoIcons.IoIosPaper />,
        cName: "sub-nav"
      },
      {
        title: "Cosmos",
        path: "/cosmos",
        icon: <IoIcons.IoIosPaper />,
        cName: "sub-nav"
      },
      {
        title: "MongoDB",
        path: "/mongoDB",
        icon: <IoIcons.IoIosPaper />
      },
      {
        title: "Docker",
        path: "/docker",
        icon: <IoIcons.IoIosPaper />
      },
      {
        title: "Kubernetes",
        path: "/kubernetes",
        icon: <IoIcons.IoIosPaper />,
        cName: "sub-nav"
      },
      {
        title: "Terraform",
        path: "/terraform",
        icon: <IoIcons.IoIosPaper />,
        cName: "sub-nav"
      },
      {
        title: "Azure",
        path: "/azure",
        icon: <IoIcons.IoIosPaper />
      },
      {
        title: "AWS",
        path: "/amazonWebServices",
        icon: <IoIcons.IoIosPaper />
      },
      {
        title: "Microservices",
        path: "/microservices",
        icon: <IoIcons.IoIosPaper />,
        cName: "sub-nav"
      },
      {
        title: "Rest API",
        path: "/restAPI",
        icon: <IoIcons.IoIosPaper />
      },
      {
        title: "SDLC",
        path: "/sdlc",
        icon: <IoIcons.IoIosPaper />
      }
    ]
  },
  {
    title: "Quick Search",
    path: "/search",
    icon: <AiIcons.AiOutlineSearch />
  },
  {
    title: "Blogs",
    path: "/blogs",
    icon: <FaIcons.FaBloggerB />
  },
  {
    title: "Technical Interview",
    path: "#",
    icon: <FaIcons.FaEnvelopeOpenText />,

    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,

    subNav: [
      {
        title: "Coding Interview",
        path: "/codingRound",
        icon: <IoIcons.IoIosPaper />
      },
      {
        title: "Design Patterns",
        path: "/designPatterns",
        icon: <IoIcons.IoIosPaper />
      },
      {
        title: "System Design",
        path: "/systemDesign",
        icon: <IoIcons.IoIosPaper />
      },
      ,
      {
        title: "Behavioral Q&A",
        path: "/behavioral",
        icon: <IoIcons.IoIosPaper />
      }
    ]
  },
  {
    title: "Privacy Policy",
    path: "/privacyPolicyMain",
    icon: <FaIcons.FaStickyNote />
  }
];
