import React from "react";
import books from "./jsonData/BookRecommendation.json";

const BookRecommendation = () => {
  return (
    <div>
      <h4>Recommended Book</h4>
      <ul>
        {books.map(book => (
          <li key={book.id}>
            <a href={book.link}>{book.title}</a>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default BookRecommendation;
