import React, { Component } from "react";
import juniorQuestions from "./qnaJSONdata/QnAJuniorJava.json";
import seniorQuestions from "./qnaJSONdata/QnASeniorJava.json";
import QnASideBarRight from "./QnASideBarRight";
import ScrollButton from "../../ScrollButton";

class QnAJava extends Component {
  render() {
    console.log("render");
    //const languageType = "java";

    return (
      <div className="container-fluid">
        <div className="row flex-xl-nowrap">
          <div className="col-6 col-md-2 clo-xl-2 bd-sidebar mb-2 m4"></div>
          <main
            className="col-12 col-md-9 col-xl-8 py-md-3 pl-md-5 bd-content"
            role="main"
          >
            <div className="col-xs-12">
              <h2 className="m-5">
                Top Interview Questions for Entry-Level Java Developers
              </h2>
              <p>
                Discover the power and versatility of Java, a popular
                programming language developed by Sun Microsystems and now owned
                by Oracle. With object-oriented, class-based, and concurrent
                programming features, Java is designed to be portable, making it
                easier to write code on one platform and run it on any other
                platform with a Java Virtual Machine installed. Enjoy the
                benefits of automatic memory management through garbage
                collection and a vast library of classes and packages that
                provide solutions to common programming tasks, from file I/O to
                creating user interfaces. From enterprise and web applications
                to games and embedded systems, Java is a widely used language
                that offers scalability and robust security features. Whether
                you're a junior or experienced developer, learning Java is a
                valuable skill that is likely to remain in high demand for years
                to come.
              </p>

              <p className="text-left lead text-justify text-break">
                Discover the top interview questions for Junior Java Developers
                that are commonly asked and prepare yourself for success.
              </p>
              <p className="text-left lead text-justify text-break">
                <ol>
                  {juniorQuestions.map(juniorQuestion => (
                    <li key={juniorQuestion.id}>
                      <h5>{juniorQuestion.question}</h5>
                      <div>
                        <p>{juniorQuestion.answer}</p>
                      </div>
                    </li>
                  ))}
                </ol>
              </p>
            </div>
            <div className="col-xs-12">
              <h2 className="m-5">
                Top Interview Questions for Senior Java Developers
              </h2>
              <p className="text-left lead text-justify text-break">
                Prepare for your Senior/Experienced Java Developer job interview
                by reviewing these commonly asked interview questions.
              </p>

              <p className="text-left lead text-justify text-break">
                <ol>
                  {seniorQuestions.map(seniorQuestion => (
                    <li key={seniorQuestion.id}>
                      <h5>{seniorQuestion.question}</h5>
                      <div>
                        <p>{seniorQuestion.answer}</p>
                      </div>
                    </li>
                  ))}
                </ol>
              </p>
            </div>
          </main>
          <div className="col-6 col-md-2 clo-xl-2 mb-2 border-left">
            <QnASideBarRight />
          </div>
        </div>
        <ScrollButton />
      </div>
    );
  }
}

export default QnAJava;
