import React, { Component } from "react";
import AbilityTestContent from "./AbilityTestContent";
import { Form, FormControl } from "react-bootstrap";
import QnASideBarRight from "../questionanswer/QnASideBarRight";

class AbilityTest extends Component {
  render() {
    return (
      <div className="container-fluid">
        <div className="row flex-xl-nowrap">
          <div className="col-6 col-md-2 clo-xl-2 bd-sidebar mb-2 border-right border-bottom">
            <Form className="form-center p-2">
              <FormControl type="text" placeholder="Search" className="" />
            </Form>
            <ul>
              <a href="/consul">what is consul ?</a>
            </ul>
            <ul>
              <a href="/cdn">what is a cdn ?</a>
            </ul>
            <ul>
              <a href="/git">Lets Understand GIT.</a>
            </ul>
            <ul>
              <a href="/projects">sample Projects for you</a>
            </ul>
            <ul>
              <a href="/blind75">
                {" "}
                Must know 75 Big Tech coding interview questions
              </a>
            </ul>
          </div>
          <main
            className="col-12 col-md-9 col-xl-8 py-md-3 pl-md-5 bd-content"
            role="main"
          >
            <AbilityTestContent />
          </main>
          <div className="col-6 col-md-2 clo-xl-2 mb-2 border-left">
            <QnASideBarRight />
          </div>
        </div>
      </div>
    );
  }
}

export default AbilityTest;
