import React, { Component } from "react";
import MainPageContent from "./MainPageContent";
import Services from "./Services";
import { Form, FormControl } from "react-bootstrap";

class QnAPaginationAll extends Component {
  render() {
    return (
      <div className="container-fluid">
        <div className="row flex-xl-nowrap">
          <div className="col-6 col-md-2 clo-xl-2 bd-sidebar mb-2 border-right border-bottom">
            <Form className="form-center p-2">
              <FormControl type="text" placeholder="Search" className="" />
            </Form>
            <ul>
              <div> Quick Links </div>
            </ul>
            <ul>
              <a href="#">Business Details</a>
            </ul>
            <ul>
              <a href="#">Company Details</a>
            </ul>
            <ul>
              <a href="#">Industry Recognition</a>
            </ul>
            <ul>
              <a href="#">FAQs</a>
            </ul>
          </div>
          <main
            className="col-12 col-md-9 col-xl-8 py-md-3 pl-md-5 bd-content"
            role="main"
          >
            <MainPageContent />
            <Services />
          </main>
          <div className="col-6 col-md-2 clo-xl-2 mb-2 border-left">
            <h2>Popular Blogs</h2>
            <ul>
              <a href="https://medium.com/swlh"> The Startup</a>
            </ul>
            <ul>
              <a href="https://towardsdatascience.com/">Towards Data Science</a>
            </ul>
            <ul>
              <a href="https://medium.com/personal-growth">Personal Growth</a>
            </ul>
            <ul>
              <a href="https://uxdesign.cc/">UX Collective</a>
            </ul>
            <ul>
              <a href="https://betterhumans.pub/">Better Humans</a>
            </ul>
          </div>
        </div>
      </div>
    );
  }
}

export default QnAPaginationAll;
