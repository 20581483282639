/*import React, { useState } from "react";
import { Button, Container } from "react-bootstrap";
import Quiz from "./Quiz";
import Review from "./Review";
import Result from "./Result";
import { ReactQuestionData } from "./ReactQuestionData";
import { JavaQuestionData } from "./JavaQuestionData";

const App = () => {
  const [quizStarted, setQuizStarted] = useState(false);
  const [selectedQuiz, setSelectedQuiz] = useState("");
  const [showResult, setShowResult] = useState(false);
  const [score, setScore] = useState(0);
  const [questions, setQuestions] = useState([]);
  const [answers, setAnswers] = useState([]);

  const handleQuizStart = quizType => {
    setSelectedQuiz(quizType);
    setQuizStarted(true);

    if (quizType === "Java") {
      setQuestions(JavaQuestionData);
    } else if (quizType === "React") {
      setQuestions(ReactQuestionData);
    }

    setAnswers(Array(questions.length).fill(null));
  };

  const handleAnswerSubmit = () => {
    const correctAnswers = questions.map(q => q.correctAnswer);
    const newScore = answers.filter((a, i) => a === correctAnswers[i]).length;
    setScore(newScore);
    setShowResult(true);
  };

  const handleAnswerChange = (index, value) => {
    const newAnswers = [...answers];
    newAnswers[index] = value;
    setAnswers(newAnswers);
  };

  const handleRestartQuiz = () => {
    setQuizStarted(false);
    setSelectedQuiz("");
    setShowResult(false);
    setScore(0);
    setQuestions([]);
    setAnswers([]);
  };

  const handleReviewQuiz = () => {
    setShowResult(false);
  };

  const renderQuizSelector = () => {
    return (
      <Container>
        <h2>Select a Quiz:</h2>
        <Button variant="primary" onClick={() => handleQuizStart("Java")}>
          Java Quiz
        </Button>{" "}
        <Button variant="primary" onClick={() => handleQuizStart("React")}>
          React Quiz
        </Button>
      </Container>
    );
  };

  const renderQuiz = () => {
    return (
      <Quiz
        questions={questions}
        answers={answers}
        handleAnswerChange={handleAnswerChange}
        handleAnswerSubmit={handleAnswerSubmit}
      />
    );
  };

  const renderResult = () => {
    return (
      <Result
        score={score}
        totalQuestions={questions.length}
        handleRestartQuiz={handleRestartQuiz}
        handleReviewQuiz={handleReviewQuiz}
      />
    );
  };

  const renderReview = () => {
    return (
      <Review
        questions={questions}
        answers={answers}
        handleRestartQuiz={handleRestartQuiz}
      />
    );
  };

  return (
    <>
      {quizStarted
        ? showResult
          ? renderResult()
          : renderQuiz()
        : renderQuizSelector()}
      <Container className="mt-4 text-center">
        {showResult ? (
          <Button variant="primary" onClick={handleRestartQuiz}>
            Restart Quiz
          </Button>
        ) : (
          ""
        )}
      </Container>
    </>
  );
};

export default App; */

import React, { useState } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import Description from "./Description";
import Quiz from "./Quiz";
import "./QuizApp.css";

function QuizApp() {
  const [showQuiz, setShowQuiz] = useState(false);
  const [selectedQuiz, setSelectedQuiz] = useState("");

  const startQuiz = quizType => {
    console.log("value assigned " + quizType);
    setSelectedQuiz(quizType);
    setShowQuiz(true);
  };

  return (
    <div className="App">
      <Container>
        <Row className="mt-5">
          <Col>
            <h1 className="text-center">Java and React Quiz</h1>
          </Col>
        </Row>
        {!showQuiz && (
          <Row className="mt-5">
            <Col>
              <Description />
            </Col>
          </Row>
        )}
        <Row className="mt-5">
          <Col className="text-center">
            {!showQuiz && (
              <>
                <Button
                  className="mr-3"
                  color="primary"
                  onClick={() => startQuiz("java")}
                >
                  Java Quiz
                </Button>
                <Button color="primary" onClick={() => startQuiz("react")}>
                  React Quiz
                </Button>
              </>
            )}
          </Col>
        </Row>
        {showQuiz && (
          <Row className="mt-5">
            <Col>
              <Quiz quizType={selectedQuiz} />
            </Col>
          </Row>
        )}
      </Container>
    </div>
  );
}

export default QuizApp;
