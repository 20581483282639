export const JavaQuestionData = [
  {
    question: "What is Java?",
    answers: [
      "A programming language",
      "A web browser",
      "A database management system",
      "An operating system"
    ],
    correctAnswer: 0
  },
  {
    question: "What is the difference between JDK and JRE?",
    answers: [
      "JDK includes JRE and development tools, while JRE only includes the Java Runtime Environment",
      "JRE includes JDK and development tools, while JDK only includes the Java Runtime Environment",
      "JDK is for client-side applications, while JRE is for server-side applications",
      "JRE is for client-side applications, while JDK is for server-side applications"
    ],
    correctAnswer: 0
  },
  {
    question: "What is a constructor in Java?",
    answers: [
      "A special method that is called when an object is created",
      "A type of loop",
      "A way to define variables",
      "A way to declare classes"
    ],
    correctAnswer: 0
  }
];

export default JavaQuestionData;
